<template>
	<v-radio v-bind="$attrs" color="primary" v-on="$listeners">
		<template v-slot:label>
			<slot />
		</template>
	</v-radio>
</template>

<script>
export default {
	name: 'WRadio'
}
</script>
